<div dougsModalTitle>
  <h6>{{ data.connection.name }} - {{ subTitle }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small">
    Vous allez être redirigé vers notre prestataire de synchronisation bancaire sécurisé. Une fois votre compte
    authentifié, vous serez automatiquement redirigé vers dougs.fr.
  </p>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
  <dougs-button type="button" (click)="redirect()">
    {{ buttonText }}
  </dougs-button>
</div>
