import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Connection, CONNECTION_STATUS_REDIRECT_TYPE } from '@dougs/synchronized-accounts/dto';

@Component({
  selector: 'dougs-connection-redirect-action-modal',
  templateUrl: './connection-redirect-action-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, ModalFooterDirective, ButtonComponent],
})
export class ConnectionRedirectActionModalComponent implements OnInit {
  subTitle = '';
  buttonText = '';

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      connection: Connection;
      redirectUrl: string;
    },
    private readonly modalRef: ModalRef,
  ) {}

  ngOnInit(): void {
    switch (this.data.connection.status.redirectType) {
      case CONNECTION_STATUS_REDIRECT_TYPE.CONNECT:
        this.subTitle = 'Ajout de compte bancaire';
        this.buttonText = 'Ajouter un compte';
        break;
      case CONNECTION_STATUS_REDIRECT_TYPE.EDIT:
        this.subTitle = 'Mise à jour de vos informations bancaires';
        this.buttonText = 'Mettre à jour';
        break;
      case CONNECTION_STATUS_REDIRECT_TYPE.PRO_VALIDATION:
        this.subTitle = 'Validation de compte professionnel';
        this.buttonText = 'Valider votre compte professionnel';
        break;
      case CONNECTION_STATUS_REDIRECT_TYPE.SCA:
        this.subTitle = 'Authentification forte requise';
        this.buttonText = 'Authentifier votre compte';
        break;
    }
  }

  redirect(): void {
    this.modalRef.close(true);
    window.open(this.data.redirectUrl, '_blank');
  }
}
