<div dougsModalTitle>
  <h6 class="mr-16">Charte de sécurité</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16"><b>Identifiants bancaires sécurisés</b></p>
  <p class="small mb-16">
    Les identifiants bancaires sont nécessaires pour synchroniser automatiquement vos comptes bancaires avec Dougs. Ils
    ne sont jamais communiqués en clair à l'application Dougs, un chiffrement asymétrique RSA de niveau bancaire assure
    leur protection.
  </p>
  <p class="small mb-16"><b>Lecture seule</b></p>
  <p class="small mb-16">
    Aucune opération bancaire n'est réalisable depuis Dougs. Il est, par exemple, impossible d'effectuer des virements.
    De plus, vos identifiants sont chiffrés dans une base de données sécurisée et sont utilisés uniquement par nos
    algorithmes dans le seul but de rapatrier vos opérations chaque jour.
  </p>
  <p class="small mb-16"><b>Gestion de vos données</b></p>
  <p class="small mb-16">
    Les données (soldes des comptes + relevés) sont synchronisées avec Dougs quotidiennement afin que vous puissiez
    bénéficier de l'état de votre trésorerie en temps réel au sein de l'application. Vous pouvez à tout instant
    interrompre le partage de vos comptes via vos paramètres.
  </p>
  <p class="small mb-16"><b>Confidentialité des données</b></p>
  <p class="small mb-16">
    Vos données vous appartiennent et ne font l'objet d'aucune vente. Elles ne sortent pas de Dougs. Vous pouvez effacer
    l'intégralité de vos données en suivant la procédure de désinscription.
  </p>
  <p class="small mb-16"><b>Désinscription</b></p>
  <p class="small mb-16">
    À n'importe quel moment il vous est possible de vous désinscrire en 2 clics. Si notre service ne vous convenait pas
    ou que vous souhaitiez tout simplement effacer l'ensemble de vos données, il vous suffit de cliquer sur « Paramètres
    » > « Désinscription » pour que l'intégralité de vos données soit effacée de nos serveurs. Aucune trace ne sera
    conservée.
  </p>
  <p class="small mb-16"><b>Contactez-nous</b></p>
  <p class="small mb-16">
    Pour tout renseignement, contactez-nous par téléphone au 04.28.29.62.62 ou par email à
    <a href="mailto:support@dougs.fr">support&#64;dougs.fr</a>
    .
    <br />
    <em>Dougs SAS au capital de 10000€ - 7 Avenue Adolphe Max 69005 Lyon</em>
    <br />
    Société inscrite à l'Ordre des Experts-Comptables de Lyon
  </p>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Fermer</dougs-button>
</div>
