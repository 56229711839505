<div dougsModalTitle>
  <h6>Mise à jour de vos informations bancaires</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16">
    Dougs ne parvient plus à se synchroniser avec votre banque
    <b>{{ data.connection.name }}</b>
    . Cela correspond {{ data.connectionAccounts.length > 1 ? 'aux comptes' : 'au compte' }} :
  </p>
  <ul class="mb-16">
    <li *ngFor="let account of data.connectionAccounts">
      <p class="small">{{ account.name }}</p>
    </li>
  </ul>
  <p class="small mb-16" *ngIf="data.connection.status.code === 'wrongpass'">
    Vous avez peut-être changé d'identifiants de connexion ? Afin de rétablir la synchronisation, veuillez saisir vos
    nouveaux identifiants. Certaines banques nécessitent une mise à jour régulière des identifiants, vous pouvez le
    vérifier en vous connectant au site de votre banque.
  </p>
  <dougs-connection-fields
    [fields]="fields"
    [ngModelOptions]="{ standalone: true }"
    [(ngModel)]="credentials"
  ></dougs-connection-fields>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
  <dougs-button type="button" (click)="onSubmit()">
    <i class="fal fa-sync fa-spin mr-4" *ngIf="pending"></i>
    Mettre à jour
  </dougs-button>
</div>
