import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Attachment } from '@dougs/core/files';
import { PanelInfoComponent } from '@dougs/ds';
import { SourceField } from '@dougs/synchronized-accounts/dto';
import { ConnectionStateService } from '@dougs/synchronized-accounts/shared';
import { ConnectionFieldComponent } from '../connection-field/connection-field.component';

@Component({
  selector: 'dougs-connection-fields',
  templateUrl: './connection-fields.component.html',
  styleUrls: ['./connection-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConnectionFieldsComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [NgFor, NgIf, PanelInfoComponent, ConnectionFieldComponent, FormsModule],
})
export class ConnectionFieldsComponent implements ControlValueAccessor {
  attachment: Attachment | null = null;
  credentials: Record<string, unknown> = {};

  private _fields!: SourceField[];

  @Output() uploadFile: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  @Input()
  set fields(fields: SourceField[]) {
    this._fields = fields;
  }

  get fields(): SourceField[] {
    return this._fields;
  }

  constructor(
    private readonly connectionStateService: ConnectionStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async onUploadFile(file: File): Promise<void> {
    this.attachment = await this.connectionStateService.uploadCSV(file);
    if (this.attachment) {
      this.uploadFile.emit(this.attachment);
    }
    this.cdr.markForCheck();
  }

  onTouched!: () => void;
  propagateChange!: (value: unknown) => void;

  onChange(field: SourceField, value: string): void {
    if (field.type !== 'file') {
      this.credentials[field.name] = value;

      if (this.propagateChange) {
        this.propagateChange(this.credentials);
      }
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: Record<string, unknown>): void {
    this.credentials = obj;
  }
}
