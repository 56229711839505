<ng-container *ngIf="isInit">
  @if (showPopularSources && !seeMore) {
    <input
      *ngIf="!isDisabled"
      class="mb-8"
      placeholder="Rechercher votre banque"
      [formControl]="searchControl"
      (click)="seeMoreSources()"
      type="text"
      dougsFormFieldControl
      dougsAutofocus
    />
    @if (!seeMore) {
      <div class="popular-sources mt-16">
        <div class="popular-sources__list">
          @for (popularSource of popularSources | async; track popularSource.id) {
            <div
              [class.popular-sources__list__source--selected]="selectedSource?.id === popularSource.id"
              (click)="onSelectPopularSource(popularSource)"
              class="popular-sources__list__source pointer"
            >
              <img [src]="'images/banks/' + popularSource.logo" [alt]="'Logo ' + popularSource.name" class="mb-4" />
              {{ popularSource.name }}
            </div>
          }
        </div>
        <p (click)="seeMoreSources()" class="flex small color-primary-700 bold p-8 mt-12 pointer text-center">
          Voir plus
          <i class="fal fa-chevron-down ml-4"></i>
        </p>
      </div>
    }
  } @else {
    <input
      *ngIf="!isDisabled && !selectedSource"
      class="mb-8"
      placeholder="Rechercher votre banque"
      [formControl]="searchControl"
      type="text"
      dougsFormFieldControl
      dougsAutofocus
    />
    <ul class="searched-account-container" *ngIf="!selectedSource">
      <ng-container *ngFor="let sourceGroup of filteredSources | async | keyvalue">
        <li class="p-16" *ngIf="sourceGroup.value.length > 0">
          <h6>
            {{
              sourceGroup.key === 'manual' ? 'Import manuel' : sourceGroup.key === 'invoice' ? 'Factures' : 'Banques'
            }}
          </h6>
        </li>
        <li
          class="px-16 py-8 searched-account-container__source"
          (click)="onSelectSource(source)"
          *ngFor="let source of sourceGroup.value"
        >
          <p class="small">{{ source.name }}</p>
        </li>
      </ng-container>
    </ul>
    <dougs-select
      class="mb-8"
      [ngClass]="{ 'select--resettable': !isDisabled }"
      [disabled]="true"
      (click)="resetSource()"
      dougsFormFieldControl
      *ngIf="selectedSource"
      [ngModel]="0"
    >
      <dougs-select-option [value]="0">{{ selectedSource.name }}</dougs-select-option>
    </dougs-select>
  }
</ng-container>
