<form (ngSubmit)="onSubmit()">
  <div dougsModalTitle>
    <h6 class="mr-16">
      {{ data?.mergeData ? 'Mettre à jour un compte' : 'Ajouter un compte' }}
    </h6>
    <i (click)="close()" [ngClass]="{ disabled: pending }" class="close-icon fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-add-connection-login
      [mergeData]="data?.mergeData"
      [synchronizedAccount]="data?.synchronizedAccount"
    ></dougs-add-connection-login>
  </div>
  <div dougsModalFooter>
    <dougs-button (click)="close()" color="secondary">Fermer</dougs-button>
    <dougs-button [disabled]="!canSubmit()" color="primary" type="submit">
      <i *ngIf="pending" class="fal fa-sync fa-spin"></i>
      {{ data?.mergeData ? 'Mettre à jour mon compte' : 'Ajouter mon compte' }}
    </dougs-button>
  </div>
</form>
