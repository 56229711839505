<div class="recovering-date-container p-16 mt-8">
  <h5 class="color-admin">Date de récupération</h5>
  <div class="recovering-date-container__dates mt-8">
    <dougs-input-datepicker
      #maxInput="ngModel"
      [noMargin]="true"
      [isAdmin]="true"
      [ngModel]="value?.providerMinDate"
      (ngModelChange)="!maxInput.invalid && onModelChange($event, 'providerMinDate')"
      label="Minimum"
    ></dougs-input-datepicker>
    <dougs-input-datepicker
      #minInput="ngModel"
      [noMargin]="true"
      [isAdmin]="true"
      [ngModel]="value?.providerMaxDate"
      (ngModelChange)="!minInput.invalid && onModelChange($event, 'providerMaxDate')"
      label="Maximum"
    ></dougs-input-datepicker>
  </div>
</div>
