import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Attachment } from '@dougs/core/files';
import {
  ControlFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { SourceField } from '@dougs/synchronized-accounts/dto';

@Component({
  selector: 'dougs-connection-field, [dougs-connection-field]',
  templateUrl: './connection-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConnectionFieldComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    FormFieldComponent,
    LabelFormFieldDirective,
    NgIf,
    SelectComponent,
    ControlFormFieldDirective,
    FormsModule,
    NgFor,
    SelectOptionComponent,
    FileInputComponent,
    FilePillComponent,
    InputDatepickerComponent,
  ],
})
export class ConnectionFieldComponent implements ControlValueAccessor {
  private _value!: unknown;

  set value(value: unknown) {
    this._value = value;
    this.propagateChange(value);
    this.onTouched();
  }

  get value() {
    return this._value;
  }

  @Input() field!: SourceField;
  @Input() attachment?: Attachment;
  @Output() uploadFile: EventEmitter<File> = new EventEmitter<File>();

  onTouched!: () => void;
  propagateChange!: (value: unknown) => void;

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(obj: unknown) {
    this._value = obj;
  }
}
