<dougs-radio-group
  [vertical]="true"
  ngModel
  (ngModelChange)="onSelect($event)"
  appearance="no-vertical-row-gap"
  *ngIf="availableConnectionAccounts.length"
>
  <ul class="searched-account-container">
    <li
      *ngFor="
        let availableConnectionAccount of showAllAvailableConnectionAccounts
          ? availableConnectionAccounts
          : (availableConnectionAccounts | slice: 0 : 2)
      "
    >
      <label class="connection-account px-16 py-8">
        <dougs-checkbox
          class="mr-8"
          [ngModel]="availableConnectionAccount.synchronized"
          (ngModelChange)="onCheck($event, availableConnectionAccount)"
          *ngIf="multipleSelection"
        ></dougs-checkbox>
        <dougs-radio [value]="availableConnectionAccount" *ngIf="!multipleSelection"></dougs-radio>
        <span class="pb-4">
          {{ availableConnectionAccount.name }} - {{ availableConnectionAccount.number }} -
          {{ availableConnectionAccount.balance | currency: 'EUR' }}
          <br />
        </span>
      </label>
    </li>
    <li *ngIf="availableConnectionAccounts.length > 2">
      <a
        class="show-more-button py-8"
        (click)="showAllAvailableConnectionAccounts = !showAllAvailableConnectionAccounts"
      >
        {{
          showAllAvailableConnectionAccounts
            ? 'Masquer les comptes non synchronisés'
            : 'Afficher tous les comptes non synchronisés (' + availableConnectionAccounts.length + ')'
        }}
        <i class="fal ml-8" [ngClass]="showAllAvailableConnectionAccounts ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
      </a>
    </li>
  </ul>
</dougs-radio-group>

<ul class="searched-account-container mt-16" *ngIf="synchronizedConnectionAccounts.length">
  <li
    *ngFor="
      let synchronizedConnectionAccount of showAllSynchronizedConnectionAccounts
        ? synchronizedConnectionAccounts
        : (synchronizedConnectionAccounts | slice: 0 : 1)
    "
  >
    <label class="connection-account px-16 py-8 is-disabled">
      <dougs-checkbox class="mr-8" disabled="true" [ngModel]="true"></dougs-checkbox>
      <span class="pb-4">
        {{ synchronizedConnectionAccount.name }} - {{ synchronizedConnectionAccount.number }} -
        {{ synchronizedConnectionAccount.balance | currency: 'EUR' }}
        <br />
        <ng-container>
          <i class="fal fa-check-circle color-success mr-4"></i>
          Compte synchronisé
        </ng-container>
      </span>
    </label>
  </li>
  <li *ngIf="synchronizedConnectionAccounts.length > 1">
    <a
      class="show-more-button py-8"
      (click)="showAllSynchronizedConnectionAccounts = !showAllSynchronizedConnectionAccounts"
    >
      {{
        showAllSynchronizedConnectionAccounts
          ? 'Masquer les comptes déjà synchronisés'
          : 'Afficher tous les comptes déjà synchronisés (' + synchronizedConnectionAccounts.length + ')'
      }}
      <i class="fal ml-8" [ngClass]="showAllSynchronizedConnectionAccounts ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </a>
  </li>
</ul>

<ul class="searched-account-container mt-16" *ngIf="deletedConnectionAccounts.length">
  <li
    *ngFor="
      let deletedConnectionAccount of showAllDeletedConnectionAccounts
        ? deletedConnectionAccounts
        : (deletedConnectionAccounts | slice: 0 : 1)
    "
  >
    <label class="connection-account px-16 py-8 is-disabled">
      <span class="pb-4">
        {{ deletedConnectionAccount.name }} - {{ deletedConnectionAccount.number }} -
        {{ deletedConnectionAccount.balance | currency: 'EUR' }}
        <br />
        <ng-container>
          <i class="fal fa-times-circle color-error mr-4"></i>
          Compte supprimé
        </ng-container>
      </span>
    </label>
  </li>
  <li *ngIf="deletedConnectionAccounts.length > 1">
    <a class="show-more-button py-8" (click)="showAllDeletedConnectionAccounts = !showAllDeletedConnectionAccounts">
      {{
        showAllDeletedConnectionAccounts
          ? 'Masquer les comptes supprimés'
          : 'Afficher tous les comptes supprimés (' + deletedConnectionAccounts.length + ')'
      }}
      <i class="fal ml-8" [ngClass]="showAllDeletedConnectionAccounts ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </a>
  </li>
</ul>
