<dougs-form-field>
  <label dougsFormFieldLabel>
    {{ field.label }}
  </label>
  <dougs-select dougsFormFieldControl *ngIf="field.type === 'list'" [(ngModel)]="value" [placeholder]="field.label">
    <dougs-select-option *ngFor="let fieldValue of field.values" [value]="fieldValue.value">
      {{ fieldValue.label }}
    </dougs-select-option>
  </dougs-select>
  <div class="file-list">
    <dougs-file-input
      [fileInputText]="attachment ? 'Remplacer le fichier' : 'Attacher le fichier'"
      [multiple]="false"
      (uploadFiles)="uploadFile.emit($event)"
      *ngIf="field.type === 'file'"
    ></dougs-file-input>
    <dougs-file-pill
      *ngIf="field.type === 'file' && attachment"
      [canDelete]="false"
      [attachment]="attachment"
    ></dougs-file-pill>
  </div>
  <dougs-input-datepicker [(ngModel)]="value" *ngIf="field.type === 'date'"></dougs-input-datepicker>
  <input
    [pattern]="field.regex"
    [required]="field.isRequired"
    [placeholder]="field.label"
    dougsFormFieldControl
    [type]="field.type"
    [(ngModel)]="value"
    *ngIf="field.type !== 'date' && field.type !== 'file' && field.type !== 'list'"
  />
</dougs-form-field>
