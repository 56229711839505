import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-security-modal',
  templateUrl: './security-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, ModalFooterDirective, ButtonComponent],
})
export class SecurityModalComponent {}
