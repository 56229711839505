import { AsyncPipe, KeyValuePipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import {
  AutofocusDirective,
  ControlFormFieldDirective,
  LoaderComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { PopularSource, Source, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { SourceStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-source-selection',
  templateUrl: './source-selection.component.html',
  styleUrls: ['./source-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ControlFormFieldDirective,
    AutofocusDirective,
    ReactiveFormsModule,
    NgFor,
    SelectComponent,
    NgClass,
    SelectOptionComponent,
    LoaderComponent,
    AsyncPipe,
    KeyValuePipe,
  ],
})
export class SourceSelectionComponent implements OnInit {
  @Input() synchronizedAccount?: SynchronizedAccount;
  @Input() selectedSource: Source | null = null;
  @Input() isDisabled = false;
  @Input() showPopularSources = false;
  @Output() selectSource: EventEmitter<Source | null> = new EventEmitter<Source | null>();

  private readonly popularSourcesConfig: PopularSource[] = [
    {
      id: 'bank:qonto-oauth@qonto-oauth',
      name: 'Qonto',
      logo: 'qonto.png',
      shouldHideOnSelect: true,
      hasBranch: false,
    },
    {
      id: 'bank:qonto@qonto',
      name: 'Qonto',
      logo: 'qonto.png',
      shouldHideOnSelect: true,
      hasBranch: false,
    },
    {
      id: 'bank:bankin@591',
      name: 'Propulse by CA',
      logo: 'propulse.png',
      shouldHideOnSelect: false,
      hasBranch: false,
    },
    {
      id: 'bank:shine@shine',
      name: 'Shine',
      logo: 'shine.png',
      shouldHideOnSelect: false,
      hasBranch: false,
    },
    {
      id: 'bank:bi@37bbb26d-d371-5333-bcea-1839b5283cec',
      name: 'PayPal',
      logo: 'paypal.png',
      shouldHideOnSelect: false,
      hasBranch: false,
    },
    {
      id: 'bank:bankin@16',
      name: 'Crédit Mutuel',
      logo: 'credit-mutuel.png',
      shouldHideOnSelect: false,
      hasBranch: true,
    },
    {
      id: 'bank:bi@e9606d38-6b0f-5f76-b573-61a4d00a927d',
      name: 'CIC',
      logo: 'cic.png',
      shouldHideOnSelect: false,
      hasBranch: false,
    },
    {
      id: 'bank:bi@de508df8-aa37-537e-a05f-1379526dfa84',
      name: 'Banque Populaire',
      logo: 'banque-populaire.png',
      shouldHideOnSelect: false,
      hasBranch: false,
    },
    {
      id: 'bank:bi@01756bd4-bb82-531c-bc39-79aaedd21449',
      name: 'BNP Paribas',
      logo: 'bnp.png',
      shouldHideOnSelect: false,
      hasBranch: true,
    },
  ];

  searchControl = new UntypedFormControl({ value: '', disabled: this.isDisabled });
  sources!: Observable<_.Dictionary<Source[]>>;
  popularSources!: Observable<PopularSource[]>;
  filteredSources!: Observable<_.Dictionary<Source[]>>;
  isInit = false;
  seeMore = false;

  constructor(
    public readonly sourceStateService: SourceStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.isDisabled) {
      await this.sourceStateService.refreshSources(this.companyStateService.activeCompany, {
        context: this.synchronizedAccount ? 'add' : 'initialize',
      });

      this.sources = this.filteredSources = this.sourceStateService.sources$.pipe(
        map((sources) => _.groupBy(sources, 'group')),
      );

      this.popularSources = this.sources.pipe(
        map((sources) => Object.values(sources).flat()),
        map((sources) => {
          return this.popularSourcesConfig
            .map((popularSource) => {
              const source: Source | undefined = sources.find((source) => source.id === popularSource.id);
              return { ...popularSource, source };
            })
            .filter((popularSource) => popularSource.source);
        }),
      );

      this.filteredSources = this.searchControl.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        switchMap((search) =>
          this.sources.pipe(
            map((value) =>
              Object.entries(value).reduce(
                (acc, [group, sources]) => ({
                  ...acc,
                  [group]: sources.filter((source) => source.name.toLowerCase().includes(search.toLowerCase())),
                }),
                {},
              ),
            ),
          ),
        ),
      );
    }

    this.isInit = true;

    this.cdr.markForCheck();
  }

  onSelectPopularSource(popularSource: PopularSource): void {
    if (popularSource.hasBranch) {
      this.onSelectSource(null);
      this.seeMore = true;
      setTimeout(() => {
        this.searchControl.setValue(popularSource.name);
      });
    } else if (popularSource.source) {
      this.onSelectSource(popularSource.source === this.selectedSource ? null : popularSource.source);

      if (popularSource.shouldHideOnSelect) {
        this.seeMore = true;
      }
    }
  }

  onSelectSource(source: Source | null): void {
    this.selectedSource = source;
    this.selectSource.emit(source);
  }

  resetSource(): void {
    if (this.isDisabled) {
      return;
    }

    this.onSelectSource(null);
    this.searchControl.setValue('');
  }

  seeMoreSources(): void {
    this.seeMore = true;
    this.resetSource();
  }
}
