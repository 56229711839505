import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { Attachment } from '@dougs/core/files';
import { ModalService } from '@dougs/ds';
import {
  Connection,
  ConnectionAccount,
  Source,
  SourceField,
  SynchronizedAccount,
  TransactionProvider,
} from '@dougs/synchronized-accounts/dto';
import {
  ConnectionStateService,
  SourceStateService,
  SynchronizedAccountStateService,
} from '@dougs/synchronized-accounts/shared';
import { MergeData } from '@dougs/task/dto';
import { SecurityModalComponent } from '../modals/security-modal/security-modal.component';
import { ConnectionService } from './connection.service';

@Injectable()
export class AddConnectionService {
  searchControl = new FormControl<string>('');

  selectedSource: Source | null = null;
  private readonly connectionAccounts: WritableSignal<ConnectionAccount[]> = signal([]);
  connectionAccounts$: Signal<ConnectionAccount[]> = this.connectionAccounts.asReadonly();
  credentials: {
    [key: string]: any;
  } = {};
  selectedConnectionAccounts: ConnectionAccount[] = [];
  createNewConnection = false;
  connectionAccountReady = false;
  fields: SourceField[] = [];
  attachment: Attachment | null = null;
  sources!: Observable<_.Dictionary<Source[]>>;
  options: { providerMinDate: string | null; providerMaxDate: string | null } = {
    providerMaxDate: null,
    providerMinDate: null,
  };
  userMessage = '';
  connection: Connection | null = null;

  connectionToMerge?: Connection;
  providerToMerge?: TransactionProvider;

  constructor(
    private readonly sourceStateService: SourceStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly connectionStateService: ConnectionStateService,
    private readonly connectionService: ConnectionService,
    private readonly synchronizedAccountStateService: SynchronizedAccountStateService,
    private readonly modalService: ModalService,
  ) {}

  async selectSourceFromSourceId(sourceId: string): Promise<void> {
    await this.onSelectSource(
      await this.sourceStateService.getSource(this.companyStateService.activeCompany.id, sourceId),
    );
  }

  async getConnectionFromMergeData(mergeData?: MergeData): Promise<void> {
    if (mergeData) {
      const connection = this.connectionStateService.getConnectionById(mergeData.connectionId);

      if (mergeData.mergeType === 'provider' && connection?.sourceId) {
        this.providerToMerge = this.synchronizedAccountStateService.getProviderById(mergeData.providerId);
        await this.selectSourceFromSourceId(connection.sourceId);
      } else if (mergeData.mergeType === 'connection') {
        this.connectionToMerge = connection;
        await this.selectSourceFromSourceId(mergeData.sourceId);
      }
    }
  }

  async onSelectSource(source: Source | null): Promise<void> {
    this.selectedSource = source;

    if (source) {
      const [fields, connectionAccounts] = await Promise.all([
        this.sourceStateService.getSourceFields(this.companyStateService.activeCompany.id, source.id),
        this.connectionStateService.getAllConnectionAccounts(this.companyStateService.activeCompany.id, source.id),
      ]);
      if (this.selectedSource) {
        this.connectionAccounts.set(
          this.providerToMerge
            ? connectionAccounts.filter(
                (connectionAccount) => connectionAccount.connectionId === this.providerToMerge?.connectionId,
              )
            : connectionAccounts,
        );
        this.createNewConnection = !connectionAccounts.length || !!this.connectionToMerge;
        this.fields = fields;
        this.connectionAccountReady = true;
      }
    } else {
      this.attachment = null;
      this.credentials = {};
      this.connectionAccountReady = false;
      this.createNewConnection = true;
    }
  }

  async addConnection(synchronizedAccount?: SynchronizedAccount): Promise<boolean> {
    if (this.connection) {
      await this.connectionStateService.createConnectionAccounts(
        this.companyStateService.activeCompany,
        this.selectedConnectionAccounts,
        {
          providerMinDate: this.options.providerMinDate,
          providerMaxDate: this.options.providerMaxDate,
        },
      );
    } else if (this.selectedSource) {
      this.connection = await this.connectionStateService.addConnection(
        this.companyStateService.activeCompany,
        this.selectedSource.id,
        [this.attachment],
        {
          synchronizedAccount: synchronizedAccount,
          credentials: this.credentials,
        },
      );

      if (this.connection) {
        try {
          await this.connectionService.handleConnectionStatus(this.connection);

          const connectionAccounts: ConnectionAccount[] | null =
            await this.connectionStateService.getConnectionAccounts(this.connection);
          this.connectionAccounts.set(connectionAccounts || []);
          this.createNewConnection = !connectionAccounts?.length;
          this.selectedConnectionAccounts = connectionAccounts?.filter((account) => account.enabled) || [];

          if (this.selectedConnectionAccounts.length) {
            return await this.createProviders(synchronizedAccount);
          } else if (connectionAccounts?.length === 1) {
            this.selectedConnectionAccounts = [connectionAccounts[0]];
            return await this.createProviders(synchronizedAccount);
          }
        } catch (e) {
          const error = e as { userMessage: string };

          if (error.userMessage) {
            this.userMessage = error.userMessage;
          }

          this.connection = null;
        }
      }
    }

    return false;
  }

  async mergeConnection(): Promise<boolean> {
    if (!this.selectedSource || !this.connectionToMerge) {
      return false;
    }

    this.connection = await this.connectionStateService.mergeConnection(
      this.companyStateService.activeCompany,
      this.selectedSource.id,
      this.connectionToMerge,
      {
        credentials: this.credentials,
      },
    );

    if (this.connection) {
      try {
        await this.connectionService.handleConnectionStatus(this.connection);
        await this.connectionStateService.synchronizeNewProviders(this.connection);
        return true;
      } catch (e) {
        const error = e as { userMessage: string };

        if (error.userMessage) {
          this.userMessage = error.userMessage;
        }

        this.connection = null;
      }
    }

    return false;
  }

  async mergeProvider(): Promise<boolean> {
    try {
      if (!this.providerToMerge) {
        return false;
      }

      const synchronizedAccount = this.synchronizedAccountStateService.getSynchronizedAccountById(
        this.providerToMerge.accountId,
      );

      if (!synchronizedAccount) {
        return false;
      }

      await this.synchronizedAccountStateService.mergeProvider(
        this.selectedConnectionAccounts[0],
        this.providerToMerge,
        synchronizedAccount,
      );

      return true;
    } catch (e) {
      const error = e as { userMessage: string };

      if (error.userMessage) {
        this.userMessage = error.userMessage;
      }

      return false;
    }
  }

  async createProviders(synchronizedAccount?: SynchronizedAccount): Promise<boolean> {
    if (synchronizedAccount) {
      await this.connectionStateService.createProviders(
        this.companyStateService.activeCompany,
        synchronizedAccount,
        this.selectedConnectionAccounts,
        {
          providerMinDate: this.options.providerMinDate,
          providerMaxDate: this.options.providerMaxDate,
        },
      );
    } else {
      await this.connectionStateService.createConnectionAccounts(
        this.companyStateService.activeCompany,
        this.selectedConnectionAccounts,
        {
          providerMinDate: this.options.providerMinDate,
          providerMaxDate: this.options.providerMaxDate,
        },
      );
    }

    return true;
  }

  async onUploadFile(file: File): Promise<void> {
    this.attachment = await this.connectionStateService.uploadCSV(file);
  }

  fieldsIsValid(): boolean {
    for (const field of this.fields) {
      if (field.type !== 'info') {
        if (field.isRequired && !this.credentials[field.name as string]) {
          return false;
        }
        if (field.regex && !new RegExp(field.regex).test(this.credentials[field.name as string])) {
          return false;
        }
      }
    }

    return true;
  }

  openSecurityModal(e: Event): void {
    e.preventDefault();
    this.modalService.open(SecurityModalComponent);
  }
}
