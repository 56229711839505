import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ButtonComponent,
  FlashMessagesService,
  FlashType,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { Connection, CONNECTION_STATUS_ACTION, ConnectionAccount, SourceField } from '@dougs/synchronized-accounts/dto';
import { ConnectionStateService, SourceStateService } from '@dougs/synchronized-accounts/shared';
import { ConnectionFieldsComponent } from '../../components/add-connection-login/connection-fields/connection-fields.component';
import { ConnectionService } from '../../services/connection.service';

@Component({
  selector: 'dougs-connection-update-action-modal',
  templateUrl: './connection-update-action-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgFor,
    NgIf,
    ConnectionFieldsComponent,
    FormsModule,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class ConnectionUpdateActionModalComponent implements OnInit {
  fields: SourceField[] = [];
  credentials = {};
  pending = false;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      connection: Connection;
      connectionAccounts: ConnectionAccount[];
    },
    private readonly sourceStateService: SourceStateService,
    private readonly connectionStateService: ConnectionStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly connectionService: ConnectionService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.fields = await this.sourceStateService.getSourceFields(
      this.data.connection.companyId,
      this.data.connection.sourceId,
    );

    this.cdr.markForCheck();
  }

  async onSubmit(): Promise<void> {
    if (!this.pending) {
      this.pending = true;

      const connectionUpdated = await this.connectionStateService.updateConnection(
        this.data.connection,
        this.credentials,
      );

      if (connectionUpdated) {
        if (connectionUpdated.status.action === CONNECTION_STATUS_ACTION.UPDATE) {
          this.flashMessagesService.show(connectionUpdated.status.userMessage || '', {
            timeout: 5000,
            type: connectionUpdated.status.userMessageType || 'info',
          });
        } else {
          try {
            await this.connectionService.handleConnectionStatus(connectionUpdated);
            this.modalRef.close(true);
          } catch (e) {
            const error = e as any;
            if (error.userMessage) {
              this.flashMessagesService.show(error.userMessage, {
                type: error.userMessageType as FlashType,
                timeout: 5000,
              });
            }
          }
        }
      }

      this.pending = false;
      this.cdr.markForCheck();
    }
  }
}
