import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { FlashMessagesService, FlashType, ModalService } from '@dougs/ds';
import {
  Connection,
  CONNECTION_STATUS_ACTION,
  CONNECTION_STATUS_CODE,
  ConnectionStatusError,
  TransactionProvider,
} from '@dougs/synchronized-accounts/dto';
import { ConnectionStateService } from '@dougs/synchronized-accounts/shared';
import { ConnectionExternalActionModalComponent } from '../modals/connection-external-action-modal/connection-external-action-modal.component';
import { ConnectionRedirectActionModalComponent } from '../modals/connection-redirect-action-modal/connection-redirect-action-modal.component';
import { ConnectionUpdateActionModalComponent } from '../modals/connection-update-action-modal/connection-update-action-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  constructor(
    private readonly logger: LoggerService,
    private readonly modalService: ModalService,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly connectionStateService: ConnectionStateService,
  ) {}

  async checkConnections(connections: Connection[]) {
    for (const connectionIterated of connections) {
      const connection: Connection | null = await this.connectionStateService.getConnection(connectionIterated);

      if (!connection?.status.action) {
        continue;
      }

      const providers: TransactionProvider[] = await this.connectionStateService.getConnectionProviders(connection);

      if (!providers.some((provider) => provider.enabled)) {
        continue;
      }

      try {
        await this.handleConnectionStatus(connection);
      } catch (e) {
        if (e instanceof ConnectionStatusError && e.userMessage) {
          this.flashMessagesService.show(e.userMessage, {
            type: e.userMessageType as FlashType,
            timeout: 5000,
          });
        }
      }

      await this.connectionStateService.refreshConnections(connection.companyId);
    }
  }

  async handleConnectionStatus(connection: Connection): Promise<Connection> {
    switch (connection.status.action) {
      case null:
        if (connection.status.code !== CONNECTION_STATUS_CODE.OK) {
          throw new ConnectionStatusError(connection.status);
        }

        return connection;
      case CONNECTION_STATUS_ACTION.UPDATE:
        return await this.handleActionUpdate(connection);
      case CONNECTION_STATUS_ACTION.REDIRECT:
        return await this.handleActionRedirect(connection);
      case CONNECTION_STATUS_ACTION.EXTERNAL:
        return await this.handleActionExternal(connection);
      default:
        this.logger.error(`Unknown ${connection.status.action} action`);
        throw new ConnectionStatusError(connection.status);
    }
  }

  private async handleActionUpdate(connection: Connection): Promise<Connection> {
    const connectionAccounts = await this.connectionStateService.getAllConnectionAccounts(
      connection.companyId,
      connection.sourceId,
    );

    const result = (
      await lastValueFrom(
        this.modalService.open<Connection | null>(ConnectionUpdateActionModalComponent, {
          data: {
            connection,
            connectionAccounts,
          },
        }).afterClosed$,
      )
    ).data;

    if (result) {
      this.flashMessagesService.show('La connexion avec votre banque est rétablie', {
        type: 'success',
        timeout: 5000,
      });

      return result;
    }
    throw new Error('abort');
  }

  private async handleActionRedirect(connection: Connection): Promise<Connection> {
    const redirected = (
      await lastValueFrom(
        this.modalService.open<boolean>(ConnectionRedirectActionModalComponent, {
          data: {
            connection,
            redirectUrl: (await this.connectionStateService.getConnectionRemoteRedirectUrl(connection))
              .remoteRedirectUrl,
          },
        }).afterClosed$,
      )
    ).data;

    if (redirected) {
      const connectionUpdated: Connection = await lastValueFrom(
        this.connectionStateService.pullConnection(connection, true),
      );
      return await this.handleConnectionStatus(connectionUpdated);
    }

    throw new Error('abort');
  }

  private async handleActionExternal(connection: Connection): Promise<Connection> {
    const connectionSynchronized = (
      await lastValueFrom(
        this.modalService.open<Connection | null>(ConnectionExternalActionModalComponent, {
          data: connection,
        }).afterClosed$,
      )
    ).data;

    if (connectionSynchronized) {
      return await this.handleConnectionStatus(connectionSynchronized);
    }
    throw new Error('abort');
  }
}
