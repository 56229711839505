<div class="connection-login">
  <dougs-panel-info *ngIf="!hideDisclaimer" class="my-8 info-panel" type="default-light">
    <i class="fal fa-lock color-primary mr-8"></i>
    <p class="tiny">
      La synchronisation permet
      <b>uniquement la lecture</b>
      des opérations de votre compte professionnel. Votre comptable ne peut en aucun cas effectuer des virements. Vos
      données sont stockées sur des
      <b>serveurs sécurisés.</b>
      Votre connexion est sécurisée et les informations qui transitent sont chiffrées.
      <br />
      <a (click)="addConnectionService.openSecurityModal($event)" class="blue-cta mt-8" href="#">
        En savoir plus sur la sécurité
        <i class="fal fa-arrow-right ml-8"></i>
      </a>
    </p>
  </dougs-panel-info>
  <div class="bank-account-container my-8 p-16">
    <dougs-source-selection
      (selectSource)="onSelectSource($event)"
      [isDisabled]="!!mergeData"
      [selectedSource]="addConnectionService.selectedSource"
      [synchronizedAccount]="synchronizedAccount"
      [showPopularSources]="showPopularBanks"
    ></dougs-source-selection>
    <dougs-loader
      *ngIf="!addConnectionService.connectionAccountReady && addConnectionService.selectedSource"
    ></dougs-loader>

    <dougs-connection-accounts-selection
      (selectConnectionAccount)="addConnectionService.selectedConnectionAccounts = $event"
      *ngIf="!addConnectionService.createNewConnection && addConnectionService.connectionAccountReady"
      [connectionAccounts]="addConnectionService.connectionAccounts$()"
      [multipleSelection]="!mergeData"
    ></dougs-connection-accounts-selection>

    <dougs-connection-fields
      (uploadFile)="addConnectionService.attachment = $event"
      *ngIf="
        addConnectionService.connectionAccountReady &&
        addConnectionService.createNewConnection &&
        addConnectionService.selectedSource &&
        addConnectionService.fields.length
      "
      [(ngModel)]="addConnectionService.credentials"
      [fields]="addConnectionService.fields"
      [ngModelOptions]="{ standalone: true }"
    ></dougs-connection-fields>
    <p
      *ngIf="!addConnectionService.createNewConnection && addConnectionService.connectionAccountReady && !mergeData"
      class="tiny mt-8"
    >
      Votre compte n'est pas dans la liste ?
      <a
        (click)="
          $event.preventDefault(); addConnectionService.createNewConnection = !addConnectionService.createNewConnection
        "
        class="blue-cta"
        href="#"
      >
        Connectez-le ici
      </a>
    </p>
    <p
      *ngIf="
        addConnectionService.createNewConnection &&
        addConnectionService.connectionAccountReady &&
        addConnectionService.connectionAccounts$().length &&
        !mergeData
      "
      class="tiny mt-8"
    >
      Votre compte est déjà connecté ?
      <a
        (click)="
          $event.preventDefault(); addConnectionService.createNewConnection = !addConnectionService.createNewConnection
        "
        class="blue-cta"
        href="#"
      >
        Sélectionnez-le ici
      </a>
    </p>
  </div>
  <dougs-panel-info *ngIf="addConnectionService.userMessage" class="mb-8" type="warning">
    {{ addConnectionService.userMessage }}
  </dougs-panel-info>
  <dougs-connection-recovery-dates
    *ngIf="addConnectionService.selectedSource && userStateService.loggedInUser.isAccountantOrAdmin && !mergeData"
    [(ngModel)]="addConnectionService.options"
    [ngModelOptions]="{ standalone: true }"
  ></dougs-connection-recovery-dates>
</div>
