import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { Connection, CONNECTION_STATUS_ACTION } from '@dougs/synchronized-accounts/dto';
import { ConnectionStateService } from '@dougs/synchronized-accounts/shared';

@Component({
  selector: 'dougs-connection-external-action-modal',
  templateUrl: './connection-external-action-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    PanelInfoComponent,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class ConnectionExternalActionModalComponent {
  pending = false;
  hasAlreadyTry = false;

  constructor(
    @Inject(MODAL_DATA) public connection: Connection,
    private readonly connectionStateService: ConnectionStateService,
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async done(): Promise<void> {
    if (!this.pending) {
      this.pending = true;

      const connection = await this.connectionStateService.synchronizeRemoteConnection(this.connection, true);

      if (connection && connection.status.action !== CONNECTION_STATUS_ACTION.EXTERNAL) {
        this.modalRef.close(connection);
      }

      this.hasAlreadyTry = true;
      this.pending = false;

      this.cdr.markForCheck();
    }
  }
}
