import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoaderComponent, PanelInfoComponent } from '@dougs/ds';
import { Source, SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { MergeData } from '@dougs/task/dto';
import { UserStateService } from '@dougs/user/shared';
import { AddConnectionService } from '../../services/add-connection.service';
import { ConnectionAccountsSelectionComponent } from './connection-accounts-selection/connection-accounts-selection.component';
import { ConnectionFieldsComponent } from './connection-fields/connection-fields.component';
import { ConnectionRecoveryDatesComponent } from './connection-recovery-dates/connection-recovery-dates.component';
import { SourceSelectionComponent } from './source-selection/source-selection.component';

@Component({
  selector: 'dougs-add-connection-login',
  templateUrl: './add-connection-login.component.html',
  styleUrls: ['./add-connection-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PanelInfoComponent,
    SourceSelectionComponent,
    NgIf,
    LoaderComponent,
    ConnectionAccountsSelectionComponent,
    ConnectionFieldsComponent,
    FormsModule,
    ConnectionRecoveryDatesComponent,
  ],
})
export class AddConnectionLoginComponent {
  @Input() mergeData?: MergeData;
  @Input() synchronizedAccount?: SynchronizedAccount;
  @Input() hideDisclaimer = false;
  @Input() showPopularBanks = false;

  constructor(
    public readonly addConnectionService: AddConnectionService,
    public readonly userStateService: UserStateService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async onSelectSource(source: Source | null): Promise<void> {
    await this.addConnectionService.onSelectSource(source);
    this.cdr.markForCheck();
  }
}
