<ng-container *ngFor="let field of fields">
  <dougs-panel-info type="default" class="mb-16" *ngIf="field.type === 'info'">
    <p class="small" [innerHTML]="field.label"></p>
  </dougs-panel-info>
  <dougs-connection-field
    (uploadFile)="onUploadFile($event)"
    [attachment]="attachment"
    *ngIf="field.type !== 'info'"
    [field]="field"
    [ngModel]="credentials ? credentials[field.name] : ''"
    (ngModelChange)="onChange(field, $event)"
  ></dougs-connection-field>
</ng-container>
