<div dougsModalTitle>
  <h6>Action requise pour la connexion à votre banque</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <p class="small mb-16">
    La banque indique qu'une action manuelle de votre part est requise. Veuillez vous connecter au site de votre banque
    avec les
    <b>mêmes identifiants bancaires que ceux fournis à Dougs</b>
    . L'action à réaliser peut être, par exemple, un changement de mot de passe, l'acceptation de conditions ou la mise
    à jour de vos données.
  </p>
  <p class="small mb-16" *ngIf="connection?.status?.originalMessage">
    Message de votre banque : «
    <em>{{ connection.status.originalMessage }}</em>
    »
  </p>
  <p class="small mb-16">
    Lorsque vous avez terminé, cliquez sur
    <b>J'ai corrigé le problème</b>
    pour relancer la synchronisation.
  </p>
  <dougs-panel-info type="error" *ngIf="hasAlreadyTry">
    <p class="small">
      La connexion à votre banque est toujours impossible. Assurez vous de vous connecter à l'espace en ligne de votre
      banque avec les mêmes identifiants que ceux fournis à Dougs. Ceux-ci peuvent être des identifiants que vous
      utilisez rarement, tel que des identifiants en lecture seule ou autre.
    </p>
  </dougs-panel-info>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
  <dougs-button type="button" (click)="done()">
    <i *ngIf="pending" class="fal fa-sync fa-spin mr-4"></i>
    J'ai corrigé le problème
  </dougs-button>
</div>
