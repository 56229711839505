import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputDatepickerComponent } from '@dougs/ds';

@Component({
  selector: 'dougs-connection-recovery-dates',
  templateUrl: './connection-recovery-dates.component.html',
  styleUrls: ['./connection-recovery-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConnectionRecoveryDatesComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [InputDatepickerComponent, FormsModule],
})
export class ConnectionRecoveryDatesComponent implements ControlValueAccessor {
  value: { providerMinDate: string; providerMaxDate: string } = { providerMinDate: '', providerMaxDate: '' };

  onModelChange(date: string, key: string): void {
    if (this.propagateChange) {
      this.value = {
        ...this.value,
        [key]: date,
      };

      this.propagateChange(this.value);
    }
  }

  onTouched!: () => void;
  propagateChange!: (value: { providerMinDate: string; providerMaxDate: string }) => void;

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: { providerMinDate: string; providerMaxDate: string }) {
    this.value = value;
  }
}
